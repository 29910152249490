.headfaq{
    color: #f9c203; 
}
.question-box p{
   font-size: 16px;
}
.question{
   background-color: rgb(82, 80, 80);
}

