.headingcarousel {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: black; */
  z-index: 2;
}
.headingcarousel h2 {
  font-size: 6.5rem;
  color: rgba(222, 186, 44, 1);
  letter-spacing: 7px;
  font-weight: bold;
}
.carouselhead{
    padding-top: 55px;
}

.typewriter{
    font-size: 1.6rem;
    font-weight: 600;
    color: rgb(121, 217, 255);
    padding: 10px;
  }

.headingcarousel .desc {
  font-size: 2.2rem;
  color: rgb(255, 255, 255);
  
}

.carosel_imagebox {
  height: 95vh;
  filter: brightness(50%);
  transition: filter 0.5s ease;
  -webkit-filter: brightness(50%);
}
.carousel-image-container:hover .carosel_imagebox {
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 761px) and (max-width: 968px) {
  .headingcarousel {
    margin-top: 40px;
  }
}


@media (max-width: 1006px) {
    .headingcarousel {
        top: 38%;
      }
    .carosel_imagebox {
      height: 75vh;
    }
    .typewriter{
        font-size: 1.4rem;
        font-weight: 600;
        color: rgb(121, 217, 255);
        padding: 10px;
      }
    .headingcarousel h2 {
        font-size: 5rem;
        color: rgba(222, 186, 44, 1);
        letter-spacing: 7px;
        font-weight: bold;
      }
    .headingcarousel .desc {
        font-size: 1.5rem;
        color: rgb(255, 255, 255);
        
      }
  }

@media (max-width: 768px) {
  .carosel_imagebox {
    height: 60vh; 
}
.headingcarousel {
    top: 35%;
  }
  .typewriter{
    font-size: 1.2rem;
    font-weight: 600;
    color: rgb(121, 217, 255);
    padding: 10px;
  }
    .headingcarousel h2 {
        font-size: 3.5rem;
        letter-spacing: 7px;
        font-weight: bold;
      }
    .headingcarousel .desc {
        font-size: 1.1rem;
        
      
  }
}

@media (max-width: 480px) {
  .carosel_imagebox {
    height: 35vh; 
  }
  .headingcarousel {
    top: 25%;
    margin-top: 30px;
  }
  .typewriter{
    font-size: 1rem;
    font-weight: 600;
    color: rgb(121, 217, 255);
    padding: 10px;
  }
  .headingcarousel h2 {
    font-size: 2rem;
    letter-spacing: 4px;
    font-weight: bold;
  }
.headingcarousel .desc {
    font-size: .8rem;
    
  }
}
