.profile-container {
    font-family: Arial, sans-serif;
    color: #fff;
    background-color: #121212;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .profile-header {
    width: 100%;
    padding: 20px;
    background-color: #1c1c1c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
  }
  
  .profile-logo {
    font-size: 24px;
    font-weight: bold;
    color: #f0c20a;
  }
  
  .profile-nav {
    display: flex;
    gap: 20px;
  }
  
  .profile-nav-link {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  
  .profile-nav-link.active {
    color: #f0c20a;
  }
  
  .profile-box {
    background-color: #1c1c1c;
    padding: 30px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #f0c20a;
    cursor: pointer;
  }
  
  .profile-title {
    color: #f0c20a;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .profile-label {
    color: #f0c20a;
    margin: 10px 0 5px;
    text-align: left;
    font-size: 14px;
  }
  
  .profile-input {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    background-color: #2c2c2c;
    color: #fff;
  }
  
  .profile-button {
    padding: 10px;
    background-color: #f0c20a;
    color: #1c1c1c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  