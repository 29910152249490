/* Individual link styling */
.nav-links .nav-link {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative; /* Needed for the pseudo-element */
  padding-bottom: 5px; /* Space for the underline */
}

/* Hover effect for the nav links */
.nav-links .nav-link:hover {
  color: #bebb00; /* Change text color when hovering */
}

/* Create the underline on hover */
.nav-links .nav-link::after {
  content: ''; /* Empty content for the underline */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Make it as wide as the text */
  height: 3px; /* Adjust the height of the underline */
  background-color: #bebb00; /* Yellow color for the underline */
  transform: scaleX(0); /* Start with the underline scaled to 0 */
  transform-origin: bottom right; /* Scale from the right */
  transition: transform 0.3s ease; /* Smooth transition for the underline */
}

/* Show the underline on hover */
.nav-links .nav-link:hover::after {
  transform: scaleX(1); /* Scale the underline to full width */
  transform-origin: bottom left; /* Scale from the left when hovering */
}

/* Add static underline for the active link (indicating selected tab) */
.nav-links .nav-link.active::after {
  transform: scaleX(1); /* Full width for active tab */
  transform-origin: bottom left;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Make it as wide as the text */
  height: 3px; /* Adjust the height of the underline */
  background-color: #bebb00; /* Yellow color */
  transition: none; /* No transition for the active tab underline */
}

/* --- Media Queries for Responsiveness --- */

/* Tablets (768px and below) */
@media screen and (max-width: 768px) {
  .header {
    padding: 15px 20px;
  }

  .logo img {
    height: 50px; /* Adjust height for smaller screens */
  }

  .nav-links {
    gap: 10px;
  }

  .nav-links .nav-link {
    font-size: 14px; /* Reduce font size slightly */
  }

  .separator {
    font-size: 16px; /* Adjust separator size */
  }
}

/* Mobile screens (470px and below) */
@media screen and (max-width: 470px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .nav-links .nav-link {
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .separator {
    display: none; /* Hide separators on small screens for cleaner look */
  }
}
