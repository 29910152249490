.inventorypage{
    padding-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.mainbox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transform-style: preserve-3d;
    width: 1100px;

}
.inventorycard{
    position: relative;
    height: 320px;
    width: 320px;
    margin: 20px;
    transform-style: preserve-3d;
    perspective: 1000px;
}
.inventorybox{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 2s ease;
    -webkit-transition: 2s ease;
    -moz-transition: 2s ease;
    -ms-transition: 2s ease;
    -o-transition: 2s ease;
}
.inventorybox .imagebox{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}
.inventorycard:hover .inventorybox{
    transform: rotateY(180deg);
}
.inventorybox .imagebox img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.inventcontentbox{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: aqua;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
}
.inventcontentbox div{
    transform-style: preserve-3d;
    padding: 20px;
    background: linear-gradient(45deg, rgb(57, 133, 231), rgb(1, 95, 142));
    transform: translateZ(100px);
}
.inventcontentbox div h2{
    color: white;
    font-size: 18px;
    /* letter-spacing: 1px; */
    font-weight: 600;

}
.inventcontentbox div p {
    text-align: justify;
}

.inventorycard .box .contentbox div p{
    color: white;
    font-size: 13px;
}
