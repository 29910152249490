/* Main container styling */
.container {
  font-family: 'Arial', sans-serif;
  color: #fff;
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  margin: 0 auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
}

/* Requests box */
.requestsBox {
  padding: 20px;
  background-color: #2b2b2b;
  border-radius: 8px;
}

/* Title styling */
.title {
  font-size: 28px;
  text-align: center;
  color: #f9d423;
  margin-bottom: 25px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

/* Requests list */
.requestsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Individual request card styling */
.requestCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 2px solid #f9d423;
  border-radius: 10px;
  background-color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s;
}

.requestCard:hover {
  transform: scale(1.02);
}

/* Logo container */
.logoContainer {
  height:80px;
  width: 120px;
  margin-right: 20px;
}

/* Logo image */
.logoImage {
  width: 100%;
  border: 2px solid #f9d423;
}

/* Request information */
.requestInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
}

.primaryDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.primaryDetails span {
  background: #444;
  padding: 5px 10px;
  border-radius: 5px;
}

.additionalDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin-top: 10px;
  background: #444;
  padding: 10px;
  border-radius: 5px;
  color: #e0e0e0;
}

.additionalDetails .members ul {
  margin: 5px 0;
  padding: 0;
  list-style-type: disc;
  padding-left: 20px;
}

.additionalDetails .reason p {
  margin: 5px 0;
  font-style: italic;
}

/* Icons container */
.icons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

/* Common styling for both approve and reject buttons */
.icon-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #FFD700;
  border-radius: 5px; /* Square-shaped */
  background-color: #444;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Hover effects */
.icon-button:hover {
  background-color: #FFD700;
  transform: scale(1.1);
}

/* Active (click) effects */
.icon-button:active {
  transform: scale(0.95);
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.5);
}

/* Approve button styling */
.icon-button.approve {
  color: green;
}

/* Reject button styling */
.icon-button.reject {
  color: red;
}

/* --- Media Queries for Responsiveness --- */

/* Tablets (768px and below) */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
    padding: 15px;
  }

  .title {
    font-size: 24px;
  }

  .requestCard {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .logoContainer {
    width: 50px;
    margin-right: 0;
  }

  .primaryDetails {
    gap: 5px;
    font-size: 14px;
  }

  .additionalDetails {
    font-size: 14px;
  }

  .icons {
    justify-content: flex-start;
  }
}

/* Mobile screens (470px and below) */
@media screen and (max-width: 470px) {
  .container {
    width: 95%;
    padding: 10px;
  }

  .title {
    font-size: 20px;
  }

  .requestCard {
    padding: 8px;
  }

  .primaryDetails,
  .additionalDetails {
    font-size: 12px;
  }

  .logoContainer {
    width: 40px;
  }

  .icons {
    gap: 5px;
  }
}
