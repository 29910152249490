.contact-container {
  position: relative;
  height: auto; 
  padding: 20px;
}

.contact-container::before {
  content: "";
  background: url("../assets/contactbg.jpg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: -1;
}

.headercontact h1 {
  margin-top: 10vh;
  font-size: 3.5vh;
  color: rgb(199, 239, 40);
  font-weight: 700;
  text-align: center;
}

.headercontact p {
  margin: 3vh 0;
  font-size: 2.5vh;
  color: white;
  font-weight: 400;
  text-align: center;
}

.contact-content {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.contact-form {
  width: 550px;
  background-color: #333;
  padding: 25px;
}

.contact-form h3 {
  margin-bottom: 22px;
  color: rgb(202, 213, 53);
  font-size: 2.5vh;
  font-weight: 700;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 22px;
  background-color: #333;
  border-bottom: 2px solid #565656;
  color: #fff;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #565656;
  font-size: 1.9vh;
  font-weight: 700;
}

.contact-form button {
  background-color: yellow;
  color: black;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: yellowgreen;
}

.contact-info {
  width: 288px;
  background-color: black;
  padding: 20px;
}

.contact-info h3, .contact-info h4 {
  color: rgb(202, 213, 53);
}

.contact-info p {
  font-size: 1.8vh;
  color: white;
}

.map {
  margin-top: 20px;
}




@media screen and (max-width: 963px) {
  .headercontact h1 {
    font-size: 3vh;
    margin-top: 20vh;
  }
  .headercontact p {
    font-size: 2.2vh;
  }

  .contact-form {
    width: 66%;
    padding: 20px;
  }
  .contact-info {
    width: 66%;
    padding: 20px;
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 760px) {
  .headercontact h1 {
    font-size: 3vh;
    margin-top: 10vh;
  }
  .headercontact p {
    font-size: 2.2vh;
  }

  .contact-form {
    width: 66%;
    padding: 20px;
  }
  .contact-info {
    width: 66%;
    padding: 20px;
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .contact-form input, .contact-form textarea {
    padding: 10px;
  }

  .contact-form button {
    padding: 10px;
  }
}

@media screen and (max-width: 470px) {
  .headercontact h1 {
    font-size: 2.5vh;
    margin-top: 10vh;
  }
  .headercontact p {
    font-size: 2vh;
  }

  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-form, .contact-info {
    width: 100%;
    padding: 15px;
  }

  .contact-form button {
    padding: 10px;
  }

  .map iframe {
    width: 100%;
    height: 150px;
  }
}

@media screen and (max-width: 360px) {
  .headercontact h1 {
    font-size: 2.2vh;
    margin-top: 10vh;
  }
  .headercontact p {
    font-size: 1.8vh;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1.6vh;
  }

  .contact-form button {
    padding: 10px 50px;
    font-size: 1.6vh;
  }
}
