.project-slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  margin: auto;
  background-color: #111;
  min-height: 100vh;
  color: white;
}

.project-title {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin-top: 5%;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 2px solid #fff;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
}

.project-title img {
  width: 240px;
  height: 200px;
}

.project-box {
  background-color: #1c1c1c;
  border: 1px solid #333;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
  display: flex;
  height: 450px;
  width: 80%;
  max-width: 1200px;
  margin-bottom: 50px;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.project-box:hover {
  box-shadow: 0 8px 24px rgba(255, 255, 255, 0.5);
  transform: translateY(-5px);
}

.slider-button-left,
.slider-button-right {
  height: 50px;
  width: 50px;
  background-color: #fff;
  color: #111;
  border-radius: 20%;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;

  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.slider-button-left {
  left: -60px;
  margin: -25px;
}

.slider-button-right {
  right: -60px;
  margin: -25px;
}

.slider-button-left:hover,
.slider-button-right:hover {
  background-color: #f0f0f0;
  transform: scale(1.1) translateY(-50%);
}

.project-box .project-name {
  color: yellow;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  text-shadow: 0px px 5px rgba(176, 162, 162, 0.736);
}

.project-box .image-slider {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 450px;
  height: auto;
  padding: 15px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-box:hover .image-slider {
  transform: scale(1.02);
}

.project-box .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  flex-grow: 1;
}

.project-box .project-description {
  color: #ddd;
  font-size: 18px;
  line-height: 1.6;
  padding-right: 20px;
  text-align: justify;
  overflow-y: scroll;
}

@media only screen and (max-width: 1000px) {
  .project-box {
    flex-direction: column;
    height: auto;
  }

  .project-box .image-slider {
    width: 100%;
    height: 300px;
    border-radius: 15px 15px 0 0;
  }

  .project-box .column {
    padding: 20px;
  }

  .slider-button-left {
    left: -30px;
  }

  .slider-button-right {
    right: -30px;
  }
}

@media only screen and (max-width: 600px) {
  .project-box .project-description {
    font-size: 12px;
    padding-right: 0;
  }

  .slider-button-left,
  .slider-button-right {
    height: 40px;
    width: 40px;
    font-size: 20px;
  }

  .slider-button-left {
    left: -20px;
  }

  .slider-button-right {
    right: -20px;
  }
}

.project-slider-container .row button {
  background-color: #1c1c1c;
  color: white;
  border: 2px solid yellow;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7);
  position: relative;
}

.project-slider-container .row {
  margin-bottom: 15px;
}

.project-slider-container .row button:hover,
.year-selector button:hover {
  background-color: #fff;
  color: #111;
}

.project-slider-container .year-selector button {
  background-color: #1c1c1c;
  color: yellow;
  border: 2px solid #fff;
  margin: 0px 0px;
  padding: 0px 10px;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7);
}

.project-slider-container .all-years-dropdown {
  background-color: #1c1c1c;
  color: yellow;
  border: 2px solid #fff;
  margin: 5px 0px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.project-slider-container .all-years-dropdown:hover {
  background-color: #fff;
  color: #111;
}

.image-slider {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.inner-slider-button-left,
.inner-slider-button-right {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.3s ease;
  border-radius: 50%;
  z-index: 1;
}

.inner-slider-button-left:hover,
.inner-slider-button-right:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.inner-slider-button-left {
  left: 10px;
}

.inner-slider-button-right {
  right: 10px;
}
