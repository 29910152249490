.nav {
  display: flex;
  align-items: center;
  background-color: black;
  position: fixed;
  width: 100%;
  color: white;
  justify-content: center;
  padding: 10px 80px;
  z-index: 1000;
}
.nav img{
  padding-right: 22px;
  height: 65px;
}
.links { 
  display: flex;
  flex-direction: row;  
  align-items: center; 
  gap:50px
}

.nav a {
  color: inherit;
  text-decoration: none;
  position: relative;
}

.nav-link.active {
  color: #f9c203; /* Change color of active link */
}

.nav-link:hover {
  color: #91ff00; /* Change color on hover */
}

.nav-link::after {
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #f9c203;
  transition: width 0.3s ease, left 0.3s ease;
  width: 0;
}

.nav-link:hover::after {
  left: 0;
  width: 100%;
}

#mobile {
  display: none;
}

#mobile i {
  color: white;
}

#mobile #bar {
    font-size: 24px;
  cursor: pointer;
}

/* Responsive Styles */
@media screen and (max-width: 760px) {
  .links {
    display: flex;
    flex-direction: column;   
    position: fixed; 
    overflow-x: hidden;
    z-index: 20;
    top: 69px;
    right: 0;
    width: 100%;
    margin-right: 1px;
    padding: 40px 0 40px 10px;
    height:100%; 
    background: linear-gradient(
      180deg,
      #1b1b1b 0%,
      rgba(27, 27, 27, 0.9) 87.67%,
      rgba(27, 27, 27, 0.3) 108.88%
    );  
    transition: 0.3s ease-in-out;
  }
    .active1{
    right: -900px;
  }

  .active2{
    right: 0;
  }

  
  
  #mobile {
    display: block;
  }
  .nav{
    justify-content: space-between;
  }
  .navs li {
    margin-bottom: 25px;
  }
}
